import React, {useEffect, useState} from 'react';
import './App.css';
import { Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// Pages 
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import GettingStartedPage from './pages/GettingStartedPage/GettingStartedPage';
import WordBankPage from './pages/WordBankPage/WordBankPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import TablePage from './pages/TablePage/TablePage';
import DatafeedPage from './pages/DatafeedPage/DatafeedPage';
import GoogleUsernamePage from './pages/GoogleUsernamePage/GoogleUsernamePage';
import AddInitialConnectionPage from './pages/AddInitialConnectionpage/AddInitialConnectionPage';

// Components
import Navbar from './components/Navbar/Navbar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import LoadingState from './components/LoadingState/LoadingState';

function App() {
  const [state, setState] = useState({isAuthenticated: false, isLoading: true});

  useEffect(() => {
    const token = localStorage.FBAuthToken;
    if(token) {
      const decodedToken = jwtDecode(token);
      if(decodedToken.exp * 1000 < Date.now()) {
        setState({isAuthenticated: false, isLoading: false})
        localStorage.removeItem("FBAuthToken");
      } else {
        setState({isAuthenticated: true, isLoading: false})
      }
    } else {
      setState({isAuthenticated: false, isLoading: false})
      localStorage.removeItem("FBAuthToken");
    }
  }, []);

  return (
    <div className="App">
      {
        state.isAuthenticated ? 
        <Navbar />
        : null
      }
      {
        state.isLoading ? <LoadingState /> : (
          <Switch>
            <PublicRoute exact path='/' authenticated={state.isAuthenticated} component={HomePage} />
            <PublicRoute path="/login" authenticated={state.isAuthenticated} component={LoginPage}></PublicRoute>
            <PublicRoute path="/register" authenticated={state.isAuthenticated} component={RegisterPage}></PublicRoute>
            <PublicRoute path="/AddConnection" authenticated={state.isAuthenticated} component={AddInitialConnectionPage}></PublicRoute>
            <PublicRoute path='/GoogleUsername' component={GoogleUsernamePage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path='/GettingStarted' component={GettingStartedPage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path='/WordBank' component={WordBankPage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path='/Datafeed/:username/:feed' component={DatafeedPage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path='/Profile/:page/:username' component={ProfilePage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path="/Query" component={DashboardPage} authenticated={state.isAuthenticated}/>
            <PrivateRoute path='/Table/:id/:database' component={TablePage} authenticated={state.isAuthenticated}/>
          </Switch>
        )
      }
    </div>
  );
}

export default App;
