import React, {useState} from 'react';
import './UserOverview.scss';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Skeleton from '@yisheng90/react-loading/lib/Skeleton';
import DatafeedCard from '../DatafeedCard/DatafeedCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

export default function UserOverview(props) {
    const {
        connections,
        user,
        feedData,
        setCurrentUser,
        setFeedData,
        userFavourites,
        currentUser,
        setUserFavourites
    } = props;

    const [alert, setAlert] = useState(null);
    const classes = useStyles();
    
    return (
        <div className={CLASSNAME + '_profilePage-overview'} >
            {
                alert ? 
                <div className={classes.root}>
                    <Alert severity="error">{alert}</Alert>
                </div>
                :
                null
            }
            <div style={{marginRight: '2rem'}}>
            <div className={CLASSNAME + '_profilePage-overview-content-card'}>
                <h3 className={CLASSNAME + '_profilePage-overview-content-title'}>About</h3>
                {
                    user ? 
                    <div>
                    <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Full name: {user.name}</h3>
                    <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Email: {user.email}</h3>
                    <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Institutional Affiliation: {user.institution}</h3>
                    <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Bio: {user.bio}</h3>
                    </div>
                    :
                    <Skeleton height="30px" width= "15rem" style={{padding: "0 15px", marginTop: 0}}/>
                }
            </div>
            <div className={CLASSNAME + '_profilePage-overview-content-card'}>
                <h3 className={CLASSNAME + '_profilePage-overview-content-title'}>Connections</h3>
                <div className={CLASSNAME + '_profilePage-overview-content-databases'}>
                {
                    connections && connections.length !== 0 ? connections.map((connection, index) => (
                        <div key={connection + index} className={CLASSNAME + '_profilePage-content-databases-card'}>
                            <Link>{connection.title}</Link>
                            <div className={CLASSNAME + '_profilePage-content-databases-card-about'}>
                                <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Username: {connection.username}</h3>
                                <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Hostname: {connection.hostname}</h3>
                                <h3 className={CLASSNAME + '_profilePage-content-databases-card-about-subtitle'}>Root: {connection.port}</h3>
                            </div>
                        </div>
                    ))
                    :
                    <Skeleton height="30px" width= "15rem" style={{padding: "0 15px", marginTop: 0}}/>
                }
                </div>
            </div>
            <div className={CLASSNAME + '_profilePage-overview-content-card'}>
                <h3 className={CLASSNAME + '_profilePage-content-title'}>Favourites</h3>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <h5 className={CLASSNAME + '_profilePage-content-empty'}>{user && user.name} has no favourites</h5>
                </div>
            </div>
            </div>
            <div style={{width: 650}}>
                {
                    user && feedData && feedData.length === 0 ?
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <h5 className={CLASSNAME + '_profilePage-content-empty'}>{user && user.name} has no posts</h5>
                    </div>
                    :
                    null
                }
                {
                    feedData ? feedData.map(data => (
                        <DatafeedCard 
                            key={data.postId}
                            postId={data.postId}
                            user={user}
                            postUser={data.user}
                            postDate={data.createdAt}
                            postMessage={data.message}
                            postTableData={data.tableData}
                            postUserImage={data.userImage}
                            favouriteCount={data.favouriteCount}
                            commentCount={data.commentCount}
                            setAlert={setAlert}
                            setCurrentUser={setCurrentUser}
                            setFeedData={setFeedData}
                            userFavourites={userFavourites}
                            currentUser={currentUser}
                            setUserFavourites={setUserFavourites}
                        />
                    ))
                    :
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <CircularProgress color="black" />
                    </div>
                }
            </div>
        </div>
    )
}