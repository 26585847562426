import React, {useState} from 'react';
import './UserConnections.scss';
import { Link } from 'react-router-dom';

// Components 
import CommonSearchbar from '../../components/CommonSearchbar/CommonSearchbar';
import AddConneciton from '../../components/AddConnection/AddConnection';
import OptionButton from '../../components/OptionButton/OptionButton';

const CLASSNAME = 'databot';

export default function UserConnections(props) {
    const {
        connections
    } = props;
    
    const [searchDatabase, setSearchDatabase] = useState(null);
    const [showAddConnection, setShowAddConnection] = useState(false);

    const onSearchChange = (e) => {
        setSearchDatabase(e.target.value);
    }

    console.log(searchDatabase);

    const handleCloseModal = () => {
        setShowAddConnection(false);
    };

    return (
        <div className={CLASSNAME + '_userConnections'}>
            <div className={CLASSNAME + '_userConnections-search'}>
                <CommonSearchbar 
                    placeholder='Search connections...'
                    onSearchChange={onSearchChange}
                />
                <button
                    className={CLASSNAME + '_userConnections-search-new'}
                    onClick={() => setShowAddConnection(true)}
                >
                    <i className={'material-icons'}>library_add</i>
                    <p style={{margin:0, marginTop:3, marginLeft: 10}}>New</p>
                </button>
            </div>
            <div className={CLASSNAME + '_userConnections-content'}>
            {
                connections && connections.length !== 0 && connections.map((connection, index) => (
                    <div key={connections + index} className={CLASSNAME + '_userConnections-content-card'}>
                        <div>
                            <Link>{connection.title}</Link>
                            <div className={CLASSNAME + '_userConnections-content-card-about'}>
                                <h3 className={CLASSNAME + '_userConnections-content-card-about-subtitle'}>Username: {connection.username}</h3>
                                <h3 className={CLASSNAME + '_userConnections-content-card-about-subtitle'}>Hostname: {connection.hostname}</h3>
                                <h3 className={CLASSNAME + '_userConnections-content-card-about-subtitle'}>Root: {connection.port}</h3>
                            </div>
                        </div>
                        <OptionButton direct={true} connectionId={connection.connectionId}/>
                    </div>
                ))
            }
            </div>
            <AddConneciton open={showAddConnection} handleClose={handleCloseModal}/>
        </div>
    )
}