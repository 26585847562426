import React, { useState } from 'react';
import './Datafeed.scss';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

// Components
import DatafeedCard from '../DatafeedCard/DatafeedCard';
import CircularProgress from '@material-ui/core/CircularProgress';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

export default function Datafeed(props) {
    const {
        user,
        feedData,
        userFavourites,
        setCurrentUser,
        setFeedData,
        setUserFavourites
    } = props;

    const [alert, setAlert] = useState(null);
    const classes = useStyles();

    return (
        <div className={CLASSNAME + '_datafeed'}>
            {
                alert ? 
                <div className={classes.root}>
                    <Alert severity="error">{alert}</Alert>
                </div>
                :
                null
            }
            {
                feedData ? feedData.map(data => (
                    <DatafeedCard 
                        key={data.postId}
                        postId={data.postId}
                        user={user}
                        userFavourites={userFavourites}
                        postUser={data.user}
                        postDate={data.createdAt}
                        postMessage={data.message}
                        postTableData={data.tableData}
                        postUserImage={data.userImage}
                        favouriteCount={data.favouriteCount}
                        commentCount={data.commentCount}
                        setCurrentUser={setCurrentUser}
                        setFeedData={setFeedData}
                        setAlert={setAlert}
                        setUserFavourites={setUserFavourites}
                    />
                ))
                :
                <div style={{display:'flex', justifyContent:'center'}}>
                    <CircularProgress color="black" />
                </div>
            }
        </div>
    )
    
}