import React, {useEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@yisheng90/react-loading';
import connectionsService from '../../services/Connections'
import './WorkspaceHeader.scss';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '1rem',
      marginTop: '0.5rem',
      minWidth: 120,
    },
}));

export default function WorkspaceHeader(props) {
    const {
        setShowDetails,
        connections,
        connection,
        setConnection,
        table,
        setTable
    } = props;
    const classes = useStyles();
    const [tableArr, setTableArr] = useState('');

    useEffect(() => {
        const getData = async () => {
            const tableData = await connectionsService.getAllTables(connection);

            if(tableData.error) {
                console.log(tableData.error)
            } else {
                const filterTables = tableData.data.tables.map((key, value) => {
                    return Object.values(key)[0];
                })

                setTableArr(filterTables);
                setTable(filterTables[0]);
            }
        }

        if(!tableArr && connection) {
            getData();
        }
    }, [connection, setTable, tableArr])

    const handleChangeTable = (event) => {
        setTable(event.target.value);
    };

    const handleChangeConnection = (event) => {
        setConnection(event.target.value);
    };

    return (
        <div className={CLASSNAME + '_workspaceHeader'}>
            {
                connections && connection ? 
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Connection</InputLabel>
                        <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={connection.title}
                        onChange={handleChangeConnection}
                        >
                        {
                            connections.map(connection => {
                                return <MenuItem key={connection.title} value={connection.title}>{connection.title}</MenuItem>
                            })
                        }
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Table</InputLabel>
                        <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={table}
                        onChange={handleChangeTable}
                        >
                        {
                            tableArr && tableArr.map(table => {
                                return <MenuItem key={table} value={table}>{table}</MenuItem>
                            })
                        }
                        </Select>
                    </FormControl>
                </div>
                :
                <Skeleton height="30px" width= "20rem" style={{marginTop: '1rem', marginLeft: '1rem'}}/>
            }
            <button className={CLASSNAME + '_workspaceHeader-button'} onClick={() => setShowDetails(true)}>
                <i className={'material-icons'}>info</i>
            </button>
        </div>
    )
}