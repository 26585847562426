import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

// Components 
import ModalHeader from '../ModalHeader/ModalHeader';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ShareModal(props) {
    const {
        handleClose,
        open,
        id,
        database
    } = props;

    const classes = useStyles();
    const url = window.location.origin + "/Table/" + id + "/" + database;

    return (
        <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
            <div className={classes.paper}>
                <ModalHeader title={"Share"} handleClose={handleClose} icon={<i className="material-icons" style={{marginRight: 5, marginTop: 2}}>share</i>}/>
                <div className={CLASSNAME + '_welcomeMessage-example'} style={{marginTop: "-15px", display: "flex"}}>
                    <p>URL: </p>
                    <p className={CLASSNAME + '_welcomeMessage-example-text'}>{url}</p>
                </div>
                <div style={{display: "flex"}}>
                    <EmailShareButton url={url}>
                        <EmailIcon size={52} round />
                    </EmailShareButton>
                    <FacebookShareButton url={'https://www.databotapp.com/'}>
                        <FacebookIcon size={52} round />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton url={url}>
                        <FacebookMessengerIcon size={52} round />
                    </FacebookMessengerShareButton>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={52} round/>
                    </LinkedinShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={52} round/>
                    </TwitterShareButton>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={52} round/>
                    </WhatsappShareButton>
                </div>
            </div>
            </Fade>
        </Modal>
        </div>
    );
}