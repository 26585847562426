import React, { useEffect, useState } from 'react';
import './ProfilePage.scss';
import Avatar from '@material-ui/core/Avatar';
import user from '../../services/User';
import connections from '../../services/Connections';
import feedService from '../../services/Feed';
import {
    useParams
} from "react-router-dom";

// Components
import UserOverview from '../../components/UserOverview/UserOverview';
import UserFavourites from '../../components/UserFavourites/UserFavourites';
import ProfileNavbar from '../../components/ProfileNavbar/ProfileNavbar';
import UpdateUserModal from '../../components/UpdateUserModal/UpdateUserModal';
import UserConnections from '../../components/UserConnections/UserConnections';

const CLASSNAME = 'databot';

function ProfilePage() {
    const [userData, setUserData] = useState(null);
    const [connectionsArr, setConnectionsArr] = useState(null);
    const [feedData, setFeedData] = useState(null);
    const { page, username} = useParams();
    const [openModal, setOpen] = useState(false);
    const [ currentUser, setCurrentUser ] = useState(null);
    const [userFavourites, setUserFavourites] = useState(null);
    const [errors, setErrors] = useState(null);
    console.log(errors);


    useEffect(() => {
        async function getData() {
            const userData = await user.getUserDetails(username);
            const connectionsData = await connections.getAllConnectionsByUser();
            const feedData = await feedService.getAllUserPosts(username);
            const currentUserData = await user.getAuthenticatedUserDetails();

            if((userData && userData.error) || (connectionsData && connectionsData.error) || (feedData && feedData.error) || (currentUserData && currentUserData.error)) {
                setErrors({
                    ...userData.error,
                    ...connectionsData.error,
                    ...feedData.error,
                    ...currentUserData.error
                })
            } else {
                setUserData(userData.data.user);
                setFeedData(feedData.data);
                setConnectionsArr(connectionsData.data);
                setCurrentUser(currentUserData.data);
                setUserFavourites(currentUserData.data.favourties);
            }
        }

        if (!userData) {
            getData();
        }
    }, [userData, username]);

    const showSection = (page) => {
        if (page === 'favourites') {
            return (
                <UserFavourites 
                    user={userData}
                    userFavourites={userFavourites}
                    setCurrentUser={setCurrentUser}
                    currentUser={currentUser}
                    setUserFavourites={setUserFavourites}
                />
            );
        } else if (page === 'overview') {
            return (
                <UserOverview
                    connections={connectionsArr}
                    feedData={feedData}
                    setFeedData={setFeedData}
                    setCurrentUser={setCurrentUser}
                    userFavourites={userFavourites}
                    user={userData}
                    currentUser={currentUser}
                    setUserFavourites={setUserFavourites}
                />
            )
        } else {
            return (
                <UserConnections 
                    connections={connectionsArr}
                />
            )
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <div className={CLASSNAME + '_profilePage'}>
            <div className={CLASSNAME + '_profilePage-content'} style={{width: "calc(100% - 200px)", left:"200px"}}>
                <div style={{padding: '15px', display: 'flex', borderBottom: "0.5px solid #ccc"}}>
                    <Avatar src={userData && userData.imageUrl ? userData.imageUrl : null} className={CLASSNAME + '_profilePage-content-avatar'}>{userData && userData.name.split(" ").length === 2 ? userData.name.split(" ")[0][0].toUpperCase() + userData.name.split(" ")[1][0].toUpperCase() : userData && userData.name[0]}</Avatar>
                    <div>
                        <h3 className={CLASSNAME + '_profilePage-content-name'}>{userData && userData.name}</h3>
                        <i className={'material-icons'} style={{position: 'absolute', top: 60, left: 110}}>supervisor_account</i>
                        <h3 className={CLASSNAME + '_profilePage-content-follow-stats'}>followers: {userData && userData.followerCount} following: {userData && userData.followingCount}</h3>
                    </div>
                </div>
                <ProfileNavbar page={page} user={userData} handleOpen={handleOpen} currentUser={currentUser && currentUser.credentials}/>
                {
                    showSection(page)
                }
                {
                    userData ? 
                    <UpdateUserModal 
                        open={openModal}
                        handleClose={handleCloseModal}
                        user={userData}
                    />
                    :
                    null
                }
            </div>
        </div>
    )
}
  
export default ProfilePage;