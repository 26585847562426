import React from 'react';
import './ModalHeader.scss';

const CLASSNAME = 'databot';

export default function ModalHeader(props) {
    const {
        title,
        icon,
        handleClose
    } = props;

    return (
        <div className={CLASSNAME + '_modalHeader'} style={{display: 'flex', justifyContent: 'space-between'}}>
            <h2 className={CLASSNAME + '_modalHeader-title'} style={{display: "flex"}}>{icon}{title}</h2>
            <button onClick={handleClose} className={CLASSNAME + '_modalHeader-close'}>
                <i className="material-icons" style={{marginTop: 2}}>close</i>
            </button>
        </div>
    );
}