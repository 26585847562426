import React, { useState } from 'react';
import './AddInitialConnectionPage.scss';
import connectionService from '../../services/Connections';
import Logo from '../../Assets/DATABOTLOGO.png';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% + 2rem)',
    marginLeft: '-1rem',
    marginTop: '-1rem',
    marginBottom: '1rem'
  },
  textField: {
      color: 'black',
      marginTop: "1rem"
  }
}));

const CLASSNAME = 'databot';

function AddInitialConnectionPage() {
    const [loading, setLoading] = useState(false);
    const [hostname, setHostname] = useState(null);
    const [title, setTitle] = useState(null);
    const [username, setUsername] = useState(null);
    const [port, setPort] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const [testSuccess, setTestSuccess] = useState(null);
    const classes = useStyles();
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newConnection = {
            hostname: hostname,
            port: port,
            title: title,
            username: username,
            password: password
        };

        const {error, data} = await connectionService.addConnection(newConnection);
        console.log(data);

        if(error) {
            setError(error);
        } else {
            history.push('/GettingStarted');
            window.location.reload();
        }
    }

    const testConnection = async (e) => {
        e.preventDefault();
        setLoading(true);

        const newConnection = {
            hostname: hostname,
            username: username,
            password: password
        };

        const {error, data} = await connectionService.testConnection(newConnection);

        if (error) {
            setError(error);
            setLoading(false);
        } else {
            setTestSuccess(`Test successful: ${data.connection}`)
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target;

        switch(name) {
            case 'hostname': 
                setHostname(value);
                break;
            case 'title':
                setTitle(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'port':
                setPort(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                console.log('handleChange');
        }
    }

    return (
        <div className={CLASSNAME + '_loginPage'}>
            <div className={CLASSNAME + '_loginPage--wrapper'}>
                {
                    loading ? 
                    <div className={classes.root}>
                        <LinearProgress />
                    </div>
                    :
                    null
                }
                {
                    error ? 
                    <div className={classes.root}>
                        <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
                    </div>
                    :
                    null
                }
                {
                    testSuccess ? 
                    <div className={classes.root}>
                        <Alert severity="success" onClose={() => setTestSuccess(null)}>{testSuccess}</Alert>
                    </div>
                    :
                    null
                }
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Logo} alt="logo" style={{height: 20, width: 114}}/>
                </div>
                <h2 className={CLASSNAME + '_loginPage--wrapper-title'}>Add initial connection</h2>
                <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <TextField
                        id="title"
                        name="title" 
                        type="title" 
                        value={title} 
                        className={classes.textFieldTop}
                        onChange={handleChange}
                        label="Title"
                        fullWidth
                        required
                    />
                    <TextField
                        id="hostname"
                        name="hostname" 
                        type="hostname" 
                        value={hostname} 
                        className={classes.textField}
                        onChange={handleChange}
                        label="Hostname"
                        fullWidth
                        required
                    />
                    <TextField
                        id="username"
                        name="username" 
                        type="username" 
                        value={username} 
                        className={classes.textField}
                        onChange={handleChange}
                        label="Username"
                        fullWidth
                        required
                    />
                    <TextField
                        id="port"
                        name="port" 
                        type="port" 
                        value={port} 
                        className={classes.textField}
                        onChange={handleChange}
                        label="Port"
                        fullWidth
                        required
                    />
                    <TextField
                        id="password"
                        name="password" 
                        type="password" 
                        value={password} 
                        className={classes.textField}
                        onChange={handleChange}
                        label="Password"
                        fullWidth
                        required
                    />
                    <div className={CLASSNAME + '_registerPage--wrapper-buttons'}> 
                        <button className={CLASSNAME + '_addConnection-content-button-test'} onClick={testConnection}>Test</button>
                        <button type="submit" className={CLASSNAME + '_updateUserModal-content-button-save'}>Add</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddInitialConnectionPage;