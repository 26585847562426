import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import './UserMenu.scss';

const CLASSNAME = 'databot';

export default function UserMenu(props) {
    const {
        onLogoutClick,
        user,
        setShowUserMenu
    } = props;

    const links = [
        {
            title: 'Your profile',
            path: `/Profile/overview/${user.username}`
        },
        {
            title: 'Your connections',
            path: `/Profile/connections/${user.username}`
        },
        {
            title: 'Your favourites',
            path: `/Profile/favourites/${user.username}`
        }
    ]

    function useOutside(ref) {
        useEffect(() => {
        
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) 
            && event.target.className !== 'databot_user--button') {
              setShowUserMenu(false);
            }
          }
          
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    function Outside(props) {
        const wrapperRef = useRef(null);
        useOutside(wrapperRef);
    
        return <div ref={wrapperRef} className={CLASSNAME + '_userMenu'}>{props.children}</div>;
    }

    const handleClick = () => {
        setShowUserMenu(false);
    }

    return (
        <Outside>
            <div>
                <div style={{padding: '0.38rem', display: 'flex', borderBottom: "0.5px solid #ccc", justifyContent:"center"}}>
                    <Avatar src={user && user.imageUrl ? user.imageUrl : null} className={CLASSNAME + '_userMenu-avatar'}>{user && user.name.split(" ").length === 2 ? user.name.split(" ")[0][0].toUpperCase() + user.name.split(" ")[1][0].toUpperCase() : user.name[0]}</Avatar>
                    <h3 className={CLASSNAME + '_userMenu-name'}>{user && user.username}</h3>
                </div>
                {
                    links.map(link => (
                        <Link to={link.path} className={CLASSNAME + '_userMenu-link'} onClick={handleClick}>
                            <p className={CLASSNAME + '_userMenu-link-title'}>{link.title}</p>
                        </Link>
                    ))
                }
                <button
                    className={CLASSNAME + '_userMenu-logout'}
                    onClick={onLogoutClick}
                >
                    <i className={'material-icons'}>login</i>
                    <p style={{margin:0, marginTop:3, marginLeft: 10}}>logout</p>
                </button>
            </div>
        </Outside>
    );
}