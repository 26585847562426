const isEmpty = (string) => {
    if(string == null) return true;
    else if (string.trim() === "") return true;
    else return false;
}

const isEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if(email.match(regex)){
        return true 
    }
    return false
}

const checkContinueRegister = (info) => {
    const errors  = {}
    if (isEmpty(info.email)){ 
        errors.email = "Must not be empty"
    } else if (!isEmail(info.email)) {
        errors.email = "Please enter a valid email"
    }
    if (isEmpty(info.password)) errors.password = "Must not be empty";
    if (isEmpty(info.confirmPassword)) errors.confirmPassword = "Must not be empty";
    if (info.password !== info.confirmPassword) errors.confirmPassword = "Passwords do not match"

    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
}

export default {
    checkContinueRegister
}