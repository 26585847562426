import React, { useState, useEffect } from 'react';
import './DatafeedCard.scss';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@yisheng90/react-loading';
import CommonBaseTable from '../CommonBaseTable/CommonBaseTable';
import feedService from '../../services/Feed';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import OptionButton from '../OptionButton/OptionButton';

const CLASSNAME = 'databot';

export default function DatafeedCard(props) {
    const {
        user,
        postUser,
        postDate,
        postMessage,
        postTableData,
        commentCount,
        loading,
        postUserImage,
        favouriteCount,
        postId,
        setAlert,
        userFavourites,
        setUserFavourites
    } = props;

    dayjs.extend(relativeTime);
    const [favouriteCountPlus, setFavouriteCountPlus] = useState(favouriteCount);
    const [commentCountPlus, setCommentCountPlus] = useState(commentCount);
    const [comment, setComment] = useState(null);
    const [comments, setComments] = useState(null);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const {error, data} = await feedService.getPost(postId);

            if (!error && data) {
                setComments(data && data.postData && data.postData.comments);
            }
        }

        if(!comments) {
            getData();
        }
    }, [comments, postId])

    const handleFavourite = async () => {
        const {error} = await feedService.favouritePost(postId);

        if (!error && user) {
            setUserFavourites([
                ...userFavourites,
                {postId: postId, username: user.username}
            ]);
            setFavouriteCountPlus(favouriteCountPlus + 1);
        } else {
            console.log(error)
        }
    }

    const handleUnfavourite = async () => {
        const {error} = await feedService.unfavouritePost(postId);

        if (!error && user) {
            const removeFavourite = userFavourites.filter(favourite => {
                return favourite.postId === postId ? null : favourite
            });
            setUserFavourites(removeFavourite);
            setFavouriteCountPlus(favouriteCountPlus - 1);
        } else {
            console.log(error)
        }
    }

    const handleComment = async (e) => {
        e.preventDefault();

        const commentData = {
            body: comment
        }
        if(comment !== '' && comment !== null) {
            const {error, data} = await feedService.commentOnPost(postId, commentData);
            
            if(error) {
                console.log(error);
            } else {
                setComments([
                    data,
                    ...comments
                ]);
                setCommentCountPlus(commentCountPlus + 1);
                setComment('');
            }
        }
        
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }

    return (
        <div className={CLASSNAME + '_datafeedCard'}>
            <div className={CLASSNAME + '_datafeedCard-user-info'}>
                <div style={{display: 'flex'}}>
                <Avatar src={postUserImage ? postUserImage : null} className={CLASSNAME + '_datafeedCard-avatar'}>{postUser && postUser.split(" ").length === 2 ? postUser.split(" ")[0][0].toUpperCase() + postUser.split(" ")[1][0].toUpperCase() : postUser[0]}</Avatar>
                <div style={{display: "flex"}}>
                <Link className={CLASSNAME + '_datafeedCard-name'} to={`/Profile/overview/${postUser}`}>{postUser}</Link>
                    <h3 className={CLASSNAME + '_datafeedCard-date'}>{dayjs(postDate).fromNow()}</h3>
                </div>
                </div>
                {
                    user.username === postUser ? 
                    <OptionButton direct={true} postId={postId} setAlert={setAlert}/>
                    :
                    null
                }
            </div>
            <h3 className={CLASSNAME + '_datafeedCard-message'}>{postMessage}</h3>
            <div className={CLASSNAME + '_datafeedCard-wrapper-table'}>
                {
                    loading ?
                        <Skeleton height="40vh" width= "60vw"/>
                        :
                        <div>
                            {
                                postTableData.length > 0 ? 
                                <div style={{height: "40vh", width: "100%", marginBottom: "2rem"}}>
                                    <CommonBaseTable 
                                        tableData={postTableData}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                }
                <h3 className={CLASSNAME + '_datafeedCard-wrapper-table-stats'}>favourites: {favouriteCountPlus} comments: {commentCountPlus}</h3>
            </div>
            <div className={CLASSNAME + '_datafeedCard-action'}>
                <button className={CLASSNAME + '_datafeedCard-action-button'} onClick={userFavourites && userFavourites.some(favourite => favourite.postId === postId) ? handleUnfavourite : handleFavourite}>
                    <i className="material-icons" style={userFavourites && userFavourites.some(favourite => favourite.postId === postId) ? {color: '#0f62fe'} : null}>star_rate</i>
                    <p style={{margin:0, marginTop:4, marginLeft: 10}}>Favourite</p>
                </button>
                <button className={CLASSNAME + '_datafeedCard-action-button'} onClick={() => setShowComments(!showComments)}>
                    <i className="material-icons" style={showComments ? {color: '#0f62fe'} : null}>mode_comment</i>
                    <p style={{margin:0, marginTop:4, marginLeft: 10}}>Comment</p>
                </button>
            </div>
            {
                showComments ? 
                <div>
                <div className={CLASSNAME + '_datafeedCard-comment'}>
                    <Avatar src={user && user.imageUrl ? user.imageUrl : null} className={CLASSNAME + '_datafeedCard-avatar'}>{user && user.name.split(" ").length === 2 ? user.name.split(" ")[0][0].toUpperCase() + user.name.split(" ")[1][0].toUpperCase() : user && user.name[0]}</Avatar>
                    <form onSubmit={handleComment} style={{width: '100%'}}>
                        <input type="text" onChange={handleCommentChange} value={comment} className={CLASSNAME + '_datafeedCard-comment-input'} placeholder="Write a comment..."/>
                        <button type="submit" style={{display: 'none'}}></button>
                    </form>
                </div>
                {
                    comments && comments.length > 0 && comments.map(comment => (
                        <div>
                            <div className={CLASSNAME + '_datafeedCard-comment'}>
                                <Avatar src={comment.userImage ? comment.userImage : null} className={CLASSNAME + '_datafeedCard-avatar'}>{comment.username && comment.username.split(" ").length === 2 ? comment.username.split(" ")[0][0].toUpperCase() + comment.user.name.split(" ")[1][0].toUpperCase() : comment.username[0]}</Avatar>
                                <div className={CLASSNAME + '_datafeedCard-comment-wrapper'} >
                                    <Link className={CLASSNAME + '_datafeedCard-comment-wrapper-name'} to={`/Profile/overview/${comment.username}`}>{comment.username}</Link>
                                    <p className={CLASSNAME + '_datafeedCard-comment-wrapper-message'}>{comment.body}</p>
                                </div>
                                {
                                    comment.username === user.username ? 
                                    <OptionButton direct={true} postId={postId} setAlert={setAlert}/>
                                    :
                                    null
                                }
                            </div>
                            <h3 className={CLASSNAME + '_datafeedCard-date'} style={{paddingLeft: '3.5rem', margin: 0}}>{dayjs(comment.createdAt).fromNow()}</h3>
                        </div>
                    ))
                }
                </div>
                :
                null
            }
            
        </div>
    )
}