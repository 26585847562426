import React, {} from 'react';
import './LoadingState.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logo from '../../Assets/DATABOTLOGO.png';

const CLASSNAME = 'databot';

function LoadingState() {
    
    return (
        <div className={CLASSNAME + '_loadingState'}>
            <LinearProgress />
            <div className={CLASSNAME + '_loadingState-logo'}>
                <img src={Logo} alt="logo" style={{height: 50}}/>
            </div>
        </div>
    );
}

export default LoadingState;

