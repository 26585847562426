import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import userService from '../../services/User';
import feedService from '../../services/Feed';
import { useHistory } from "react-router-dom";
import './CreatePost.scss';

// Components
import ModalHeader from '../ModalHeader/ModalHeader';
import CommonBaseTable from '../CommonBaseTable/CommonBaseTable';
import Skeleton from '@yisheng90/react-loading/lib/Skeleton';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '600px'
  },
  avatar: `
    &:hover {
        cursor: pointer;
    }
  `,
  input: {
      width: '100%',
      marginBottom: '1rem'
  }
}));

const CreatePost = (props) => {
    const {
        handleClose,
        open,
        tableData
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const [post, setPost] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    console.log(error);

    useEffect(() => {
        async function getData() {
            const userData = await userService.getAuthenticatedUserDetails();
    
            if (userData.error) {
              setError({
                ...userData.error
              })
            } else {
              setUser(userData.data.credentials);
            }
          }
    
          if(!user) {
            getData();
          }

    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (post !== null && post !== '') {
            const { data, error } = await feedService.addPost(post, tableData);
            if(data) {
                history.push(`/Datafeed/${user && user.userId}/new`);
                handleClose();
            }
            setError(error)
        } else {
            setError('Please right a message')
        }
    }

    const handleChange = (e) => {
        const { value } = e.target;
        console.log(value)
        setPost(value);
    }
    

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ModalHeader title={"Create Post"} handleClose={handleClose} icon={<i className="material-icons" style={{marginRight: 5, marginTop: 2}}>edit</i>}/>
                        {
                            user ? 
                            <div style={{padding: '0.38rem', display: 'flex'}}>
                                <Avatar src={user && user.imageUrl ? user.imageUrl : null} className={CLASSNAME + '_userMenu-avatar'}>{user && user.name.split(" ").length === 2 ? user.name.split(" ")[0][0].toUpperCase() + user.name.split(" ")[1][0].toUpperCase() : user.name[0]}</Avatar>
                                <h3 className={CLASSNAME + '_userMenu-name'}>{user && user.username}</h3>
                            </div>
                            :
                            <Skeleton height="30px" width= "15rem" style={{padding: "0 15px", marginTop: 0}}/>
                        }
                        <form onSubmit={handleSubmit} enctype="multipart/form-data">
                            <div className={CLASSNAME + '_registerPage--wrapper-buttons'}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Post"
                                    multiline
                                    placeholder="Share your thoughts..."
                                    rows={4}
                                    variant="outlined"
                                    className={classes.input}
                                    onChange={handleChange}
                                    value={post}
                                    error={error ? error : null}
                                />
                                <div style={{width: '100%', display:'flex', justifyContent: 'center'}}>
                                    {
                                        tableData ? 
                                        <div style={{height: "25vh", width: "42vw", marginBottom: "2rem"}}>
                                        <CommonBaseTable tableData={tableData}/>
                                        </div>
                                        :
                                        <Skeleton height="35vh" width= "40vw" style={{padding: "0 15px", marginTop: 0}}/>
                                    }
                                </div>
                                <button type="submit" className={CLASSNAME + '_updateUserModal-content-button-save'}>Post</button>
                                <button className={CLASSNAME + '_updateUserModal-content-button'} onClick={(e) => {e.preventDefault();handleClose();}}>cancel</button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
} 

export default CreatePost;