import axios from 'axios';
import authentication from '../services/Authentication';
import { BASE_URL } from '../utils/baseURL';
import { ALGO_URL } from '../utils/algoURL';

const getAllConnectionsByUser = async () => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + '/connections', { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const addConnection = async (connection) => {
    let error;
    let data;

    try {
        const res = await axios.post(BASE_URL + '/connection', connection, { headers: authentication.authHeader() })
        data = res.data;
    } catch(err) {
        error = err.response.data.error
    }

    return {error, data}
}

const testConnection = async (connection) => {
    let error;
    let data;

    try {
        const res = await axios.post(BASE_URL + '/connection/test', connection, { headers: authentication.authHeader() })
        data = res.data;
    } catch(err) {
        error = err.response.data.error
    }

    return {error, data}
}

const queryConnection = async (query, connection, table) => {
    let error;
    let data;

    const queryData = {
        message: query,
        database: table,
        hostname: connection.hostname,
        username: connection.username,
        password: connection.password,
        title: connection.title
    }

    try {
        const res = await axios.post(ALGO_URL + '/convert', queryData, { headers: authentication.authHeader() })
        data = res.data;
        console.log(data);
    } catch(err) {
        console.log(err.response.data);
        error = err.response.data.error
    }

    return {error, data}
}

const getAllTables = async (connection) => {
    let error;
    let data;

    try {
        const res = await axios.post(BASE_URL + `/connection/${connection.connectionId}/tables`, connection, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const deleteConneciton = async (connectionId) => {
    let error;
    let data;

    try {
        const res = await axios.delete(BASE_URL + `/connection/${connectionId}`, { headers: authentication.authHeader() })
        data = res.data;
    } catch(err) {
        error = err.response.data.error
    }

    return {error, data}
}

export default {
    getAllConnectionsByUser,
    addConnection,
    testConnection,
    queryConnection,
    getAllTables,
    deleteConneciton
}