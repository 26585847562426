import React, { useState, useEffect } from 'react';
import './LeftSidebar.scss';

// Components
import LeftNavItem from '../LeftNavItem/LeftNavItem';
import { useLocation } from 'react-router-dom'

const CLASSNAME = 'databot';

function LeftSidebar(props) {
    const {
        user
    } = props;

    const [activePage, setActivePage] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setActivePage(location.pathname);
    }, [location.pathname])

    return (
        <div className={CLASSNAME + '_leftSidebar'}>
            <div className={CLASSNAME + '__leftSidebar--wrapper'}>
                <LeftNavItem 
                    title="Getting Started"
                    icon="library_books"
                    activePage={activePage}
                />
                <LeftNavItem 
                    title="Query"
                    icon="search"
                    activePage={activePage}
                />
                <LeftNavItem 
                    title="Datafeed"
                    icon="dynamic_feed"
                    activePage={activePage}
                    user={user}
                />
                <LeftNavItem 
                    title="Word Bank"
                    icon="account_balance"
                    activePage={activePage}
                />
            </div>
        </div>
    );
}

export default LeftSidebar;

