import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CsvDownload from 'react-json-to-csv';

// Components
import ShareModal from '../ShareModal/ShareModal';

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const {
    tableData,
    id,
    database
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpen] = React.useState(false);
  const options = 
    [
      {title: 'Account settings', icon: <i className="material-icons" style={{marginRight: 5}}>manage_accounts</i>}
    ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option} selected={option === 'Pyxis'}
            onClick={
              option.title === 'Share'  ? () =>
              {
                handleClose();
                handleOpen();
              } 
              : 
              handleClose
            }>
          {
            option.title === 'Download' ? 
              <CsvDownload 
                style={
                  {
                    textDecoration:"none", 
                    border: "none", 
                    background: "none", 
                    padding: "0", 
                    fontSize: "1rem", 
                    cursor: "pointer",
                    display: "flex",
                    lineHeight: "1.5"
                  }
                } 
                filename={`${id}-databot.csv`} 
                data={tableData}
              >
                {option.icon}
                {option.title}
              </CsvDownload>   
              : 
              <div style={{display: "flex"}}>
                {option.icon}
                {option.title}
              </div>
          }
          </MenuItem>
        ))}
      </Menu>
      <ShareModal 
        open={openModal}
        handleClose={handleCloseModal}
        id={id}
        database={database}
      />
    </div>
  );
}

