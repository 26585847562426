import axios from 'axios';
import authentication from '../services/Authentication';
import { BASE_URL } from '../utils/baseURL';

const getUserDetails = async (username) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/user/${username}`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const getAuthenticatedUserDetails = async () => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + '/user', { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const getAllUsers = async () => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + '/allUsers', { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const uploadImage = async () => {

}

const markNotificationsRead = async () => {

}

export default {
    getUserDetails,
    getAuthenticatedUserDetails,
    uploadImage,
    markNotificationsRead,
    getAllUsers
}