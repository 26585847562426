import React, { useState } from 'react';
import './RegisterPage.scss';
import { Link, withRouter } from 'react-router-dom';
import authentication from '../../services/Authentication';
import validation from '../../validation/register';
import Logo from '../../Assets/DATABOTLOGO.png';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'calc(100% + 2rem)',
        marginLeft: '-1rem',
        marginTop: '-1rem',
        marginBottom: '1rem'
    },
    textField: {
        color: 'black',
        marginTop: "1rem"
    }
}));



const CLASSNAME = 'databot';

function RegisterPage() {
    const [name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [institution, setInstitution] = useState('');
    const [username, setUsername] = useState('');
    const [showNextForm, setShowNextForm] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const handleContinue = () => {
        setLoading(true)

        const {errors, valid} = validation.checkContinueRegister({email, password, confirmPassword})

        if(!valid) {
            setErrors(errors)
            setLoading(false)
        } else {
            setInterval(() => {
                setShowNextForm(true)
                setErrors({})
                setLoading(false)
            }, 2000)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newUser = {
            name,
            email,
            password,
            username,
            confirmPassword,
            phoneNumber,
            institution
        };

        const {error} = await authentication.register(newUser);

        if (!error) {
            setErrors({})
            setLoading(false)
            history.push("/AddConnection");
        } else {
            console.error(error);
            setErrors(error)
            setLoading(false)
        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target;

        switch(name) {
            case 'email': 
                setEmail(value);
                break;
            case 'fullName':
                setFullName(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConPassword(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value)
                break;
            case 'institution':
                setInstitution(value)
                break;
            case 'username':
                setUsername(value)
                break;
            default:
                console.log('handleChange');
        }
    }

    const handleGoogleLogin = async () => {
        const {error, isNewUser} = await authentication.loginUserWithGoogle();
        console.log(error, isNewUser);
        if (!error && !isNewUser) {
            setErrors({})
            setLoading(false)
            history.push("/GettingStarted");
            window.location.reload();
        } else if(!error && isNewUser) {
            setErrors({})
            setLoading(false)
            history.push("/GoogleUsername");
        } else {
            console.error(error);
            setErrors(error)
            setLoading(false)
        }
    }

    return (
        <div className={CLASSNAME + '_registerPage'}>
            <div className={CLASSNAME + '_registerPage--wrapper'}>
                {
                    loading ? 
                    <div className={classes.root}>
                        <LinearProgress />
                    </div>
                    :
                    null
                }
                {
                    errors.register ? 
                    <div className={classes.root}>
                        <Alert severity="error">{errors.register}</Alert>
                    </div>
                    :
                    null
                }
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Logo} alt="logo" style={{height: 20, width: 114}}/>
                </div>
                <h2 className={CLASSNAME + '_registerPage--wrapper-title'}>Register</h2>
                {
                    !showNextForm ? 
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="email"
                            name="email" 
                            type="email" 
                            value={email} 
                            className={classes.textField}
                            error={errors.email ? true : false}
                            helperText={errors.email}
                            onChange={handleChange}
                            label="Email"
                            fullWidth
                        />
                        <TextField
                            id="password"
                            name="password" 
                            type="password" 
                            value={password}
                            className={classes.textField} 
                            error={errors.password ? true : false}
                            helperText={errors.password}
                            onChange={handleChange}
                            label="Password"
                            fullWidth
                        />
                        <TextField
                            id="confirmPassword"
                            name="confirmPassword" 
                            type="password" 
                            value={confirmPassword}
                            className={classes.textField} 
                            error={errors.confirmPassword ? true : false}
                            helperText={errors.confirmPassword}
                            onChange={handleChange}
                            label="Confirm Password"
                            fullWidth
                        />
                        <div className={CLASSNAME + '_registerPage--wrapper-buttons'}>
                            <button type="button" onClick={handleContinue} className={CLASSNAME + '_registerPage--wrapper-button'}> Continue </button>
                        </div>
                        <div className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper'}>
                            <span className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper-line'}></span>
                            <span className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper-text'}>or</span>
                        </div>
                        <button type="button" className="google-button" onClick={handleGoogleLogin}>
                            <span className="google-button__icon">
                                <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335"/><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05"/><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4"/><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853"/></svg>
                            </span>
                            <span className="google-button__text">Sign in with Google</span>
                        </button>
                    </form>
                    :
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="username"
                            name="username" 
                            type="username" 
                            value={username}
                            className={classes.textField} 
                            error={errors.username ? true : false}
                            helperText={errors.username}
                            onChange={handleChange}
                            label="Username"
                            fullWidth
                        />
                        <TextField
                            id="fullName"
                            name="fullName" 
                            type="fullName" 
                            value={name}
                            className={classes.textField} 
                            error={errors.name ? true : false}
                            helperText={errors.name}
                            onChange={handleChange}
                            label="Full Name"
                            fullWidth
                        />
                        <TextField
                            id="phoneNumber"
                            name="phoneNumber" 
                            type="phoneNumber" 
                            value={phoneNumber}
                            className={classes.textField} 
                            error={errors.phoneNumber ? true : false}
                            helperText={errors.phoneNumber}
                            onChange={handleChange}
                            label="Phone Number"
                            fullWidth
                        />
                        <TextField
                            id="institution"
                            name="institution" 
                            type="institution" 
                            value={institution}
                            className={classes.textField} 
                            error={errors.institution ? true : false}
                            helperText={errors.institution}
                            onChange={handleChange}
                            label="Institutional Affiliation"
                            fullWidth
                        />
                        <div className={CLASSNAME + '_registerPage--wrapper-buttons'}>
                            <button type="submit" className={CLASSNAME + '_registerPage--wrapper-button'}> Register </button>
                        </div>
                        <div className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper'}>
                            <span className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper-line'}></span>
                            <span className={CLASSNAME + '__registerPage--wrapper-third-party-wrapper-text'}>or</span>
                        </div>
                        <button type="button" className="google-button" onClick={handleGoogleLogin}>
                            <span className="google-button__icon">
                                <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335"/><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05"/><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4"/><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853"/></svg>
                            </span>
                            <span className="google-button__text">Sign in with Google</span>
                        </button>
                    </form>
                }
                <p style={{marginBottom: "1rem"}}>Already have an account? <Link to="/login">Login</Link></p>
            </div>
        </div>
    )
}

export default withRouter(RegisterPage);