import React from 'react';
import './DetailsSidebar.scss';

const CLASSNAME = 'databot';

export default function DetailsSidebar(props) {
    const {
        setShowDetails,
        currentDatabase
    } = props;
    
    return (
        <div className={CLASSNAME + '_detailsSidebar'}>
            <div className={CLASSNAME + '_detailsSidebar--header'}>
                <div>
                    <h3 className={CLASSNAME + '_detailsSidebar--header-title'}>Details</h3>
                    <h3 className={CLASSNAME + '_detailsSidebar--header-subtitle'}>{currentDatabase}</h3>
                </div>
                <button className={CLASSNAME + '_detailsSidebar--header-button'} onClick={() => setShowDetails(false)}>
                    <i className={'material-icons'}>close</i>
                </button>
            </div>
            <div className={CLASSNAME + '_detailsSidebar--content'}>
                <i className="material-icons" style={{fontSize: 100}}>storage</i>
                <h3 className={CLASSNAME + '_detailsSidebar--content-title'}>{currentDatabase}</h3>
                <div className={CLASSNAME + '_detailsSidebar--content-about'}>
                    <h3 className={CLASSNAME + '_detailsSidebar--content-about-title'}>About</h3>
                    <h3 className={CLASSNAME + '_detailsSidebar--content-about-subtitle'}>Connection: </h3>
                    <h3 className={CLASSNAME + '_detailsSidebar--content-about-subtitle'}>Notes: </h3>
                </div>
            </div>
        </div>
    );
}