import axios from 'axios';
import { BASE_URL } from '../utils/baseURL';
import authentication from '../services/Authentication';

const getAllPosts = async () => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + '/feed', { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const getAllUserPosts = async (username) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/feed/user/${username}`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const addPost = async (post, tableData) => {
    let error;
    let data;

    const postData = { 
        message: post,
        tableData: tableData
    }

    try {
        const res = await axios.post(BASE_URL + '/feed', postData, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const getPost = async (postId) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/feed/${postId}`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const commentOnPost = async (postId, commentData) => {
    let error;
    let data;

    try {
        const res = await axios.post(BASE_URL + `/feed/${postId}/comment`, commentData, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const favouritePost = async (postId) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/feed/${postId}/favourite`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}

}

const unfavouritePost = async (postId) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/feed/${postId}/unfavourite`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const getFavouritedPosts = async (username) => {
    let error;
    let data;

    try {
        const res = await axios.get(BASE_URL + `/feed/favourited/${username}`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

const deletePost = async (postId) => {
    let error;
    let data;

    try {
        const res = await axios.delete(BASE_URL + `/feed/${postId}`, { headers: authentication.authHeader() })
        data = res.data
    } catch(err) {
        error = err.response.data
    }

    return {error, data}
}

export default {
    getAllPosts,
    getAllUserPosts,
    addPost,
    getPost,
    commentOnPost,
    favouritePost,
    unfavouritePost,
    deletePost,
    getFavouritedPosts
}