import axios from 'axios';
import {BASE_URL} from '../utils/baseURL';
import { auth, googleProvider } from '../config/OAuth';

const login = async (info) => {
    let error;
    
    try {
        const res = await axios.post(BASE_URL + '/login', info)
        const token = res.data.token
        localStorage.setItem("FBAuthToken", token);
    } catch (err) {
        error = err.response.data
    }
    
    return {error}
        
};

const loginUserWithGoogle =  async () => {
    let error;
    let isNewUser; 

    try {
        const googleRes = await auth.signInWithPopup(googleProvider);
        if (googleRes) {
            if(googleRes.additionalUserInfo.isNewUser) {
                isNewUser = true;
            } else {
                isNewUser = false;
                const authUser = auth.currentUser;
                const token = await authUser.getIdToken();
                localStorage.setItem("FBAuthToken", token);
            }
        }
    } catch (err) {
        console.log(err);
        error = err.response.data
    }
    
    return {error, isNewUser}
}

const register = async (info) => {
    let error;
    
    try {
        const res = await axios.post(BASE_URL + '/register', info)
        const token = res.data.token
        localStorage.setItem("FBAuthToken", token);
    } catch (err) {
        error = err.response.data
    }
    
    return {error}
};

const logout = () => {
    localStorage.removeItem("FBAuthToken");
}

const authHeader = () => {
    const token = localStorage.getItem('FBAuthToken');

    if (token) {
        return {Authorization: 'Bearer ' + token};
    } else {
        return {};
    }
}

const createGoogleUser = async (username) => {
    let error;
    const authUser = auth.currentUser;
    const token = await authUser.getIdToken();

    const newUser = {
        uid: authUser.uid,
        username: username,
        name: authUser.displayName,
        email: authUser.email,
        imageUrl: authUser.photoURL,
        phoneNumber: authUser.phoneNumber
    }  

    try {
        const res = await axios.post(BASE_URL + '/login/google', newUser);
        if(res) {
            localStorage.setItem("FBAuthToken", token);
        }
    } catch (err) {
        error = err.response.data
    }
    
    return {error}

}

export default {
    register,
    login,
    logout,
    authHeader,
    loginUserWithGoogle,
    createGoogleUser
};