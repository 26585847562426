import React from 'react';
import './ProfileNavbar.scss';
import { Link } from 'react-router-dom';
import UserOptionButton from '../UserOptionButton/UserOptionButton';
import Skeleton from '@yisheng90/react-loading/lib/Skeleton';

const CLASSNAME = 'databot';

export default function ProfileNavbar(props) {
    const {
        page,
        user,
        handleOpen,
        currentUser
    } = props;

    return (
        <div className={CLASSNAME + '_profileNavbar'}>
            {
                user ? 
                <div className={CLASSNAME + '_profileNavbar-links'}>
                    <Link to={`/Profile/overview/${user.username}`} className={CLASSNAME + '_profileNavbar-links-item'} style={page === 'overview' ? {borderBottom: '3px solid #fb8532'} : null}>
                        <i className="material-icons" style={{marginTop: 18}}>import_contacts</i>
                        <h3 className={CLASSNAME + '_profileNavbar-links-item-title'}>Overview</h3>
                    </Link>
                    <Link to={`/Profile/connections/${user.username}`} className={CLASSNAME + '_profileNavbar-links-item'} style={page === 'connections' ? {borderBottom: '3px solid #fb8532'} : null}>
                        <i className="material-icons" style={{marginTop: 18}}>wifi</i>
                        <h3 className={CLASSNAME + '_profileNavbar-links-item-title'}>Connections</h3>
                    </Link>
                    <Link to={`/Profile/favourites/${user.username}`} className={CLASSNAME + '_profileNavbar-links-item'} style={page === 'favourites' ? {borderBottom: '3px solid #fb8532'} : null}>
                        <i className="material-icons" style={{marginTop: 18}}>star_rate</i>
                        <h3 className={CLASSNAME + '_profileNavbar-links-item-title'}>Favourites</h3>
                    </Link>
                </div>
                :
                <Skeleton height="30px" width= "25rem" style={{padding: "0 15px", marginTop: '0.5rem', marginLeft: '1rem'}}/>
            }
            {
                user && currentUser ? 
                <div>
                {
                    currentUser.username === user.username ? 
                    <div className={CLASSNAME + '_profileNavbar-buttons'}>
                        <button
                            className={CLASSNAME + '_profileNavbar-buttons-update'}
                            onClick={() => {
                                handleOpen();
                            }}
                        >
                            <i className={'material-icons'} height={12}>create</i>
                            <p style={{margin:0, marginTop:3, marginLeft: 10}}>Edit profile</p>
                        </button>
                        <UserOptionButton />
                    </div>
                    :
                    <div className={CLASSNAME + '_profileNavbar-buttons'}>
                        <button
                            className={CLASSNAME + '_profileNavbar-buttons-follow'}
                            onClick={() => {
                                handleOpen();
                            }}
                        >
                            <p style={{margin:0, marginTop:3, marginLeft: 0}}>Follow</p>
                        </button>
                    </div>
                }
                </div>
                :
                <Skeleton height="30px" width= "10rem" style={{padding: "0 15px", marginTop: '0.5rem', marginRight: '1rem'}}/>
            }
        </div>
    )
}