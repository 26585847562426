import React, { useState } from 'react';
import './GoogleUsernamePage.scss';
import authentication from '../../services/Authentication';
import Logo from '../../Assets/DATABOTLOGO.png';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% + 2rem)',
    marginLeft: '-1rem',
    marginTop: '-1rem',
    marginBottom: '1rem'
  },
  textField: {
      color: 'black',
      marginTop: "1rem"
  }
}));

const CLASSNAME = 'databot';

function GoogleUsernamePage() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const {error} = await authentication.createGoogleUser(username);

        if (!error) {
            setErrors({})
            setLoading(false)
            history.push("/AddConnection");
        } else {
            console.error(error);
            setErrors(error)
            setLoading(false)
        }
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setUsername(value);
    }

    return (
        <div className={CLASSNAME + '_loginPage'}>
            <div className={CLASSNAME + '_loginPage--wrapper'}>
                {
                    loading ? 
                    <div className={classes.root}>
                        <LinearProgress />
                    </div>
                    :
                    null
                }
                {
                    errors.general ? 
                    <div className={classes.root}>
                        <Alert severity="error">{errors.general}</Alert>
                    </div>
                    :
                    null
                }
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Logo} alt="logo" style={{height: 20, width: 114}}/>
                </div>
                <h2 className={CLASSNAME + '_loginPage--wrapper-title'}>Create username</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        id="username"
                        name="username" 
                        type="username" 
                        value={username}
                        className={classes.textField} 
                        error={errors.username ? true : false}
                        helperText={errors.username}
                        onChange={handleChange}
                        label="Username"
                        fullWidth
                    />
                    <div className={CLASSNAME + '_loginPage--wrapper-buttons'}>
                        <button type="submit" className={CLASSNAME + '_loginPage--wrapper-button'}> Create username </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default GoogleUsernamePage;