import React from 'react';
import './EmptyState.scss';
import logo from '../../Assets/Databot1.svg';

const CLASSNAME = 'databot';

export default function EmptyState() {

    return (
        <div className={CLASSNAME + '_emptyState'}>
            <img src={logo} alt="Logo" className={CLASSNAME + '_emptyState-logo'}/>
            <h3 className={CLASSNAME + '_emptyState-title'}>No Data Found</h3>
        </div>
    )
}