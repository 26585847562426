import React from 'react';
import './HomePage.scss';
import logo from '../../Assets/Databot1.svg';
import { useHistory } from "react-router-dom";

const CLASSNAME = 'databot';

export default function HomePage() {

    const history = useHistory();

    function handleClickLogin() {
        history.push("/login");
    }

    function handleClickRegister() {
        history.push("/register");
    }

    return (
        <div className={CLASSNAME + '_homePage'}>
            <div className={CLASSNAME + '_homePage--wrapper'}>
                <div
                style={{
                    fontFamily: "monospace"
                }}
                className={CLASSNAME + '_logo'}
                >
                    <img src={logo} alt="Logo" className={CLASSNAME + '_logo-text'}/>
                </div>
                <div style={{height: '80vh',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className={CLASSNAME + '_homePage--wrapper-welcome'}>
                        <p className={CLASSNAME + '_homePage--wrapper-welcome-text-conv'}>Hello, I'm Databot. A natural Language Interface to Databases.</p>
                        <p className={CLASSNAME + '_homePage--wrapper-welcome-text-conv'}>I take input in plain English text, then process and transalate it into a format that can be understood by a Database Management System, namely SQL.</p>
                        <p className={CLASSNAME + '_homePage--wrapper-welcome-text-conv'}>Login to get started...</p>
                    </div>
                </div>
            </div>
            <div className={CLASSNAME + '_homePage--wrapper-right'}>
                <div>
                    <h1 className={CLASSNAME + '_homePage--wrapper-welcome-title'}>Make data work for you</h1>
                    <p className={CLASSNAME + '_homePage--wrapper-welcome-text'}>Join Databot today.</p>
                    <div className={CLASSNAME + '_homePage--wrapper-buttons'}>
                            <button onClick={handleClickRegister} className={CLASSNAME + '_homePage--wrapper-buttons-button'}> Register </button>
                            <button onClick={handleClickLogin} className={CLASSNAME + '_homePage--wrapper-buttons-button-invert'}> Login </button>
                    </div>
                    <p style={{position: 'absolute', bottom:0, right: 0, padding: '1rem'}}>© 2021 Databot</p>
                </div>
            </div>
        </div>
    );
}
