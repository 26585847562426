import * as React from 'react';
import './CommonSearchbar.scss';

const CLASSNAME = 'databot';

const CommonSearchbar = (props) => {
    const {
        onSearchChange,
        placeholder,
        onSearchClick,
        onEnter,
        value
    } = props;

    const handleKeyPress = (event) => {
        if(event.key === 'Enter' && value !== ''){
          onEnter();
        }
    }

    return (
        <div className={CLASSNAME + '_commonSearchbar'}>
            <input 
                style={{ width: 350, padding: '0.5rem' }} 
                type="text" 
                placeholder={placeholder} 
                onChange={onSearchChange}
                value={value}
                onKeyPress={handleKeyPress}
            />
            <button className={CLASSNAME + '_commonSearchbar-button'} onClick={(e) => value !== '' ? onSearchClick() : e.preventDefault()}>
                <i className="material-icons">search</i>
            </button>
        </div>
    );
};

export default CommonSearchbar;