import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import connectionService from '../../services/Connections';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import './AddConnection.scss';

// Components
import ModalHeader from '../ModalHeader/ModalHeader';
import LinearProgress from '@material-ui/core/LinearProgress';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.25rem'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '400px'
    },
    avatar: `
        &:hover {
            cursor: pointer;
        }
    `,
    textField: {
        color: 'black',
        marginTop: "1rem"
    },
    textFieldTop: {
        color: 'black',
        marginTop: "-1rem"
    },
    root: {
        width: 'calc(100% + 4rem)',
        marginLeft: '-2rem',
        marginTop: '-1rem',
        marginBottom: '1rem'
    },
}));

const AddConnection = (props) => {
    const {
        handleClose,
        open
    } = props;

    const classes = useStyles();
    const [hostname, setHostname] = useState(null);
    const [title, setTitle] = useState(null);
    const [username, setUsername] = useState(null);
    const [port, setPort] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [testSuccess, setTestSuccess] = useState(null);

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newConnection = {
            hostname: hostname,
            port: port,
            title: title,
            username: username,
            password: password
        };

        const {error, data} = await connectionService.addConnection(newConnection);
        console.log(data);

        if(error) {
            setError(error);
        } else {
            window.location.reload();
        }
    }

    const testConnection = async (e) => {
        e.preventDefault();
        setLoading(true);

        const newConnection = {
            hostname: hostname,
            username: username,
            password: password
        };

        const {error, data} = await connectionService.testConnection(newConnection);

        if (error) {
            setError(error);
            setLoading(false);
        } else {
            setTestSuccess(`Test successful: ${data.connection}`)
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target;

        switch(name) {
            case 'hostname': 
                setHostname(value);
                break;
            case 'title':
                setTitle(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'port':
                setPort(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                console.log('handleChange');
        }
    }
    

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {
                            loading ?
                            <div className={classes.root}>
                                <LinearProgress />
                            </div>
                            :
                            null
                        }
                        {
                            error ? 
                            <div className={classes.root}>
                                <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
                            </div>
                            :
                            null
                        }
                        {
                            testSuccess ? 
                            <div className={classes.root}>
                                <Alert severity="success" onClose={() => setTestSuccess(null)}>{testSuccess}</Alert>
                            </div>
                            :
                            null
                        }
                        <ModalHeader title={"Add connection"} handleClose={handleClose} icon={<i className="material-icons" style={{marginRight: 5, marginTop: 2}}>library_add</i>}/>
                        <form onSubmit={handleSubmit} enctype="multipart/form-data">
                            <TextField
                                id="title"
                                name="title" 
                                type="title" 
                                value={title} 
                                className={classes.textFieldTop}
                                onChange={handleChange}
                                label="Title"
                                fullWidth
                                required
                            />
                            <TextField
                                id="hostname"
                                name="hostname" 
                                type="hostname" 
                                value={hostname} 
                                className={classes.textField}
                                onChange={handleChange}
                                label="Hostname"
                                fullWidth
                                required
                            />
                            <TextField
                                id="username"
                                name="username" 
                                type="username" 
                                value={username} 
                                className={classes.textField}
                                onChange={handleChange}
                                label="Username"
                                fullWidth
                                required
                            />
                            <TextField
                                id="port"
                                name="port" 
                                type="port" 
                                value={port} 
                                className={classes.textField}
                                onChange={handleChange}
                                label="Port"
                                fullWidth
                                required
                            />
                            <TextField
                                id="password"
                                name="password" 
                                type="password" 
                                value={password} 
                                className={classes.textField}
                                onChange={handleChange}
                                label="Password"
                                fullWidth
                                required
                            />
                            <div className={CLASSNAME + '_registerPage--wrapper-buttons'}> 
                                <button className={CLASSNAME + '_addConnection-content-button-test'} onClick={testConnection}>Test</button>
                                <button type="submit" className={CLASSNAME + '_updateUserModal-content-button-save'}>Add</button>
                                <button className={CLASSNAME + '_updateUserModal-content-button'} onClick={(e) => {e.preventDefault();handleClose();}}>cancel</button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
} 

export default AddConnection;