import React from 'react';
import './WordBankPage.scss';

const CLASSNAME = 'databot';

function WordBankPage() {
   
    return (
        <div className={CLASSNAME + '_wordBank'}>
            <div className={CLASSNAME + '_wordBank-content'} style={{width: "calc(100% - 200px)", left:"200px"}}>
                <h3 className={CLASSNAME + '_wordBank-content-title'}>Word Bank</h3>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>SELECT</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>get, find, select, what, which, how</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>*</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>all</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>FROM</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>from</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>GROUP BY</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>group</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>ORDER BY</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>order</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>ORDER BY column DESC LIMIT 1</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>most, highest, largest</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>ORDER BY column ASC LIMIT 1</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>least, lowest, smallest</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>BETWEEEN</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>between</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>WHERE</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>where, with, for</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>SUM()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>sum, many</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>MAX()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>max, maximum</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>MIN()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>min, minimum</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>FIRST()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>first</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>LAST()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>last</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>COUNT()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>count</p>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>AVG()</h3>
                <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>average</p>
            </div>
            <div className={CLASSNAME + '_wordBank-nav'}>
                <h3 className={CLASSNAME + '_wordBank-nav-title'}>Contents</h3>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>SELECT</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>*</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>FROM</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>GROUP BY</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>ORDER BY</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>ORDER BY column DESC LIMIT 1</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>ORDER BY column ASC LIMIT 1</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>BETWEEN</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>WHERE</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>SUM()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>MAX()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>MIN()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>FIRST()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>LAST()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>COUNT()</p>
                <p className={CLASSNAME + '_wordBank-nav-title-sub'}>AVG()</p>
            </div>
        </div>
    );
}
  
export default WordBankPage;