import React, { useEffect, useState } from "react";
import connecitons from '../../services/Connections';
import './DashboardPage.scss';

// Components
import WorkspaceHeader from '../../components/WorkspaceHeader/WorkspaceHeader';
import DetailsSidebar from '../../components/DetailsSidebar/DetailsSidebar';
import QuerySection from '../../components/QuerySection/QuerySection';

const CLASSNAME = 'databot';

function DashboardPage() {
    const [showDetails, setShowDetails] = useState(false);
    const [connectionsArr, setConnectionsArr] = useState(null);
    const [connection, setConnection] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading]  = useState(true);
    const [table, setTable] = useState('');
    console.log(errors);

    useEffect(() => {
      async function getData() {
        const connecitonsData = await connecitons.getAllConnectionsByUser();

        if(connecitonsData.error) {
          setErrors(connecitonsData.error);
          setLoading(false)
        } else if (connecitonsData) {
          setConnectionsArr(connecitonsData.data);
          setConnection(connecitonsData.data[0]);
          setLoading(false);
        }
      }

      if (!connectionsArr) {
        getData();
      }
    }, [connectionsArr]);

    let style = null;
    
    if (showDetails) {
      style = {
        width: "calc(100% - 499px)", left: 200
      }
    } else {
      style = {
        width: "calc(100% - 200px)", left: 200
      }
    }

    return (
      <div className={CLASSNAME + '_dashboardPage'}> 
          <div className={CLASSNAME + '_dashboardPage--content'} style={style}>
            <WorkspaceHeader 
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              connections={connectionsArr}
              connection={connection}
              setConnection={setConnection}
              table={table}
              setTable={setTable}
            />
            <QuerySection 
              connection={connection}
              table={table}
              loading={loading}
            />
          </div>
        {
          showDetails ?
            <DetailsSidebar 
              setShowDetails={setShowDetails}
            />
            :
            null
        }
      </div>
    );
}

export default DashboardPage;