import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CsvDownload from 'react-json-to-csv';
import feedService from '../../services/Feed';
import connectionService from '../../services/Connections';

// Components
import ShareModal from '../ShareModal/ShareModal';
import DeleteModal from '../DeleteModal/DeleteModal';

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const {
    tableData,
    database,
    errors,
    direct,
    postId,
    setAlert,
    connectionId
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const options = errors || direct ? 
    [{title: 'Delete', icon: <i className="material-icons" style={{marginRight: 5}}>delete</i>, onClick: () => {setShowDeleteModal(true)}}] : 
    [
      {title: 'Download', icon: <i className="material-icons" style={{marginRight: 5}}>get_app</i>},
      {title: 'Share', icon: <i className="material-icons" style={{marginRight: 5}}>share</i>, onClick: () => {handleClose();handleOpen();}}
    ];

  const id = Date.now();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    handleClose();
  }

  const handleDelete = async () => {
    setLoading(true);

    if(connectionId) {
        const {error, data} = await connectionService.deleteConneciton(connectionId);
      
        if(error) {
          setAlert('Error deleting connection, please try again.');
          setShowDeleteModal(false);
          handleClose();
          setLoading(false);
        } else if(data) {
          setLoading(false);
          setShowDeleteModal(false);
          handleClose();
          window.location.reload();
        }
    } else {
      const {error, data} = await feedService.deletePost(postId);
    
      if(error) {
        setAlert('Error deleting post, please try again.');
        setShowDeleteModal(false);
        handleClose();
        setLoading(false);
      } else if(data) {
        setLoading(false);
        setShowDeleteModal(false);
        handleClose();
        window.location.reload();
      }
    }

  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option.title} 
            selected={option === 'Pyxis'}
            onClick={option.onClick}
          >
          {
            option.title === 'Download' ? 
              <CsvDownload 
                style={
                  {
                    textDecoration:"none", 
                    border: "none", 
                    background: "none", 
                    padding: "0", 
                    fontSize: "1rem", 
                    cursor: "pointer",
                    display: "flex",
                    lineHeight: "1.5"
                  }
                } 
                filename={`${id}-databot.csv`} 
                data={tableData}
              >
                {option.icon}
                {option.title}
              </CsvDownload>   
              : 
              <div style={{display: "flex"}}>
                {option.icon}
                {option.title}
              </div>
          }
          </MenuItem>
        ))}
      </Menu>
      <ShareModal 
        open={openModal}
        handleClose={handleCloseModal}
        id={id}
        database={database}
      />
      <DeleteModal 
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
        loading={loading}
        connectionId={connectionId}
      />
    </div>
  );
}

