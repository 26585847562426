import React, {useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import connections from '../../services/Connections';
import { Link } from 'react-router-dom';
import Skeleton from '@yisheng90/react-loading';
import './QuerySection.scss';

import CommonSearchbar from '../CommonSearchbar/CommonSearchbar';
import CommonBaseTable from '../CommonBaseTable/CommonBaseTable';
import OptionButton from '../../components/OptionButton/OptionButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreatePost from '../CreatePost/CreatePost';
import Avatar from '@material-ui/core/Avatar';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '1rem',
      minWidth: 120,
    },
}));

function QuerySection(props) {
    const {
        connection,
        loading,
        table
    } = props;

    const [type, setType] = useState('eng');
    const [query, setQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [queryLaoding, setQueryLoading] = useState(false);
    const [error, setError] = useState(null);
    const [databot, setDatabot] = useState(null);
    const [openModal, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const onSearchChange = (e) => {
        setQuery(e.target.value);
    }

    const onSearchClick = async () => {
        setQueryLoading(true)
        const {error, data} = await connections.queryConnection(query, connection, table);
        if(error) {
            setError(error)
            setQueryLoading(false)
            setTableData([])
            setQuery('')
            setDatabot(null);
        } else {
            if (data && data.databot) {
                setDatabot(data.databot);
                setQueryLoading(false)
                setError(false)
                setTableData([])
                setQuery('')
            } else {
                setQuery('')
                setTableData(data && data.tableData);
                setQueryLoading(false)
                setError(false)
                setDatabot(null);
            }
        }
    }

    const onEnter = async () => {
        setQueryLoading(true)
        const {error, data} = await connections.queryConnection(query, connection, table);
        if(error) {
            setError(error)
            setQueryLoading(false)
            setTableData([])
            setQuery('')
            setDatabot(null);
        } else {
            if (data && data.databot) {
                setDatabot(data.databot);
                setQueryLoading(false)
                setError(false)
                setTableData([])
                setQuery('')
            } else {
                setQuery('')
                setTableData(data && data.tableData);
                setQueryLoading(false)
                setError(false)
                setDatabot(null);
            }
        }
    }

    const convertDatabot = (string) => {
        if(string && string.includes('Word Bank')) {
            const splitString = string.split('Word Bank');
            return <span>{splitString[0]} <Link to="/WordBank">Word Bank</Link> {splitString[1]}</span>
        } else if(string && string.includes('https')) {
            const splitString = string.split(' ');
            return (
                <span>
                    {
                        splitString.map(word => {
                            if(word.includes('https')) {
                                return <a href={word} target="_blank" rel="noopener noreferrer">link </a>
                            } else {
                                return word + ' '
                            }
                        })
                    }
                </span>
            )   
        } else {
            return string
        }
    }

    const convertError = (error) => {
        const errorString1 = "Sorry I can't comprehend that, programmers are working hard to teach me more natural language. In the meantime checkout the ";
        const errorString2 = "to find out what I can currently comprehend.";

        return <span>{errorString1} <Link to="/WordBank">Word Bank</Link> {errorString2}</span>
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <div className={CLASSNAME + '_querySection'}>
            <div className={CLASSNAME + '_querySection-search'}>
                <div style={{display: 'flex'}}>
                    {
                        loading ? 
                        <Skeleton height="30px" width= "15rem" style={{padding: "0 15px", marginTop: 0}}/>
                        :
                        <CommonSearchbar 
                            placeholder='Query databot...'
                            onSearchChange={onSearchChange}
                            onSearchClick={onSearchClick}
                            onEnter={onEnter}
                            value={query}
                        />
                    }
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Input</InputLabel>
                        <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={type}
                        onChange={handleChange}
                        >
                        <MenuItem value={"eng"}>Plain English</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        tableData && tableData.length > 0 ? 
                        <button
                            className={CLASSNAME + '_userConnections-search-new'}
                            onClick={handleOpen}
                            style={{marginTop: '0.5rem', marginLeft: '1rem'}}
                        >
                            <i className={'material-icons'}>post_add</i>
                            <p style={{margin:0, marginTop:3, marginLeft: 10}}>Post</p>
                        </button>
                        : null
                    }
                </div>
                <div>
                    <OptionButton 
                        tableData={tableData}
                    />
                </div>
            </div>
            {
                loading ?
                <Skeleton height="55vh" width= "60vw" style={{padding: "0 15px", marginTop: 0}}/>
                :
                <div className={CLASSNAME + '_tablePage-table'}>
                    {
                        queryLaoding ? 
                        <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            <CircularProgress color="black" />
                        </div>
                        :
                        <div>
                            {
                                error || databot ? 
                                <div className={CLASSNAME + '_querySection-databot-message'} style={{marginBottom: "2rem", marginTop: "2rem"}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{padding: '15px'}}>
                                            <Avatar className={CLASSNAME + '_databotMessage-wrapper-avatar'}><i className="material-icons">storage</i></Avatar>
                                        </div>
                                        <div>
                                            <h3 className={CLASSNAME + '_databotMessage-wrapper-name'}>Databot</h3>
                                            <h3 className={CLASSNAME + '_querySection-databot-message-text'}>{error ? convertError(error) : convertDatabot(databot)}</h3>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{height: "55vh", width: "62vw", marginBottom: "2rem"}}>
                                    <CommonBaseTable 
                                        tableData={ tableData }
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            <div>
                <CreatePost
                    open={openModal}
                    handleClose={handleCloseModal}
                    tableData={tableData}
                />  
            </div>
        </div>
    )
}

export default QuerySection;