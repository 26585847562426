import React from 'react';
import './LeftNavItem.scss';
import { Link } from 'react-router-dom';

const CLASSNAME = 'databot';

export default function LeftNavItem(props) {
    const {
        title,
        icon,
        activePage,
        user
    } = props;

    return (
        <div className={CLASSNAME + '_leftNavItem'}>
            <Link to={user ? `/${title.replace(/\s/g, '')}/${user && user.username}${title === 'Datafeed' ? '/new' : ''}` : `/${title.replace(/\s/g, '')}`} className={CLASSNAME + '_leftNavItem-button'} style={activePage && activePage.includes(title.replace(/\s/g, '')) ? {backgroundColor: 'rgb(241, 239, 239)'} : null}>
                <i className="material-icons" style={activePage && activePage.includes(title.replace(/\s/g, '')) ? {color: '#0f62fe', marginLeft: '1rem', marginTop: '0.25rem'} : {color: '#596275', marginLeft: '1rem', marginTop: '0.25rem'}}>{icon}</i>
                <p className={CLASSNAME + '_leftNavItem-button-title'} style={activePage && activePage.includes(title.replace(/\s/g, '')) ? {fontWeight: 'bolder'} : null}>{title}</p>
            </Link>
        </div>
    )
}