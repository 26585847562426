import React from 'react';
import './DatafeedNavbar.scss';
import { Link } from 'react-router-dom';

const CLASSNAME = 'databot';

export default function DatafeedNavbar(props) {
    const {
        user,
        feed
    } = props;
    return (
        <div className={CLASSNAME + '_datafeedNavbar'}>
            <Link to={`/Datafeed/${user && user.userId}/new`} className={CLASSNAME + '_datafeedNavbar-item'} style={feed === 'new' ? {borderBottom: '3px solid #fb8532'} : null}>
                <i className="material-icons" style={{marginTop: 18}}>new_releases</i>
                <h3 className={CLASSNAME + '_datafeedNavbar-item-title'}>New</h3>
            </Link>
            {/* <Link to={`/Datafeed/${user && user.userId}/hot`} className={CLASSNAME + '_datafeedNavbar-item'} style={feed === 'hot' ? {borderBottom: '3px solid #fb8532'} : null}>
                <i className="material-icons" style={{marginTop: 18}}>local_fire_department</i>
                <h3 className={CLASSNAME + '_datafeedNavbar-item-title'}>Hot</h3>
            </Link>
            <Link to={`/Datafeed/${user && user.userId}/trending`} className={CLASSNAME + '_datafeedNavbar-item'} style={feed === 'trending' ? {borderBottom: '3px solid #fb8532'} : null}>
                <i className="material-icons" style={{marginTop: 18}}>trending_up</i>
                <h3 className={CLASSNAME + '_datafeedNavbar-item-title'}>Trending</h3>
            </Link> */}
        </div>
    )
}