import React, { useEffect, useState } from 'react';
import feedService from '../../services/Feed';
import Datafeed from '../Datafeed/Datafeed';
import './UserFavourites.scss';

const CLASSNAME = 'databot';

export default function UserFavourites(props) {
    const {
        user,
        userFavourites,
        setCurrentUser,
        currentUser,
        setUserFavourites
    } = props;

    const [favourties, setFavourites] = useState(null);
    const [error, setError] = useState(null);
    console.log(error, userFavourites, favourties);

    useEffect(() => {
        async function getData() {
            const {error, data} = await feedService.getFavouritedPosts(user && user.username);

            if(error) {
                setError(error);
            } else {
                setFavourites(data);
            }

        }

        if (!favourties && user) {
            getData();
        } 

    }, [favourties, user])

    return (
        <div className={CLASSNAME + '_profilePage-overview'} >
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                {
                    favourties && favourties.length === 0 ? 
                    <h5 className={CLASSNAME + '_profilePage-content-empty'}>{user && user.name} has no favourites</h5>
                    :
                    <Datafeed 
                        user={user}
                        userFavourites={userFavourites}
                        setCurrentUser={setCurrentUser}
                        setFeedData={setFavourites}
                        feedData={favourties}
                        currentUser={currentUser}
                        setFavourites={setFavourites}
                        setUserFavourites={setUserFavourites}
                    />
                }
            </div>
        </div>
    )
}
