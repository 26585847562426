import React, {useState, useEffect} from 'react';
import './Navbar.scss';
import user from '../../services/User';
import authentication from '../../services/Authentication';
// import connections from '../../services/Connections';
import Skeleton from '@yisheng90/react-loading';
import logo from '../../Assets/Databot1.svg';
import { useHistory } from "react-router-dom";

// Components
import UserMenu from '../UserMenu/UserMenu';
import LeftSidebar from '../../components/LeftSidebar/LeftSidebar';
import Avatar from '@material-ui/core/Avatar';
import Searchbar from '../../components/Searchbar/Searchbar';
import NotificationMenu from '../NotificaitonMenu/NotificationMenu';

const CLASSNAME = 'databot';

function Navbar() {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showNotificationMenu, setShowNotificationMenu] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    console.log(error);

    useEffect(() => {
      async function getData() {
        const {error, data} = await user.getAuthenticatedUserDetails();
        if (error) {
          setError(error)
          setLoading(false)
        } else {
          setLoading(false)
          setCurrentUser(data)
        }
      }

      if(!currentUser) {
        getData();
      }
    }, [currentUser]);

    const onLogoutClick = e => {
      e.preventDefault();
      authentication.logout();
      history.push('/login');
      window.location.reload();
      setShowUserMenu(false);
    };

    // const onSearchChange = (e) => {
    //     console.log(e)
    // }

    return (
          <div className={CLASSNAME + '_navbar'} style={!user ? {display: 'none'} : null}>
              <div style={{display: 'flex'}}>
                <div className={CLASSNAME + '_navbar-search'}>
                {
                  loading ? 
                  <Skeleton height="30px" width= "20rem" style={{marginTop: '0.5rem'}}/>
                  :
                  <Searchbar />
                }
                </div>
              </div>
            <div
              style={{
                fontFamily: "monospace"
              }}
              className={CLASSNAME + '_logo'}
            >
              <img src={logo} alt="Logo" className={CLASSNAME + '_logo-text'}/>
            </div>
            {
              currentUser ?
              <div style={{display: 'flex'}}>
              <button
                className={CLASSNAME + '_notification--button'}
                style={currentUser && currentUser.notifications && currentUser.notifications.length > 0 ? {marginTop: '-0.25rem', marginRight: '-1rem'} : {marginRight: '-1rem'}}
                onClick={() => setShowNotificationMenu(!showNotificationMenu)}
              >
                {
                  currentUser && currentUser.notifications && currentUser.notifications.length > 0 ? 
                  <div className={CLASSNAME + '_notification--button-notifCount'}>
                    {currentUser.notifications.length}
                  </div>
                  :
                  null
                }
                <i className={'material-icons'} style={{marginLeft: -5}}>notifications</i>
              </button> 
              <button
                style={{
                  fontFamily: "monospace",
                  display: "flex"
                }}
                className={CLASSNAME + '_user--button'}
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <Avatar src={currentUser && currentUser.credentials && currentUser.credentials.imageUrl ? currentUser.credentials.imageUrl : null} className={CLASSNAME + '_user--button-avatar'}>{currentUser && currentUser.credentials.name.split(" ").length === 2 ? currentUser.credentials.name.split(" ")[0][0].toUpperCase() + currentUser.credentials.name.split(" ")[1][0].toUpperCase() : currentUser.credentials.name[0]}</Avatar>
                <i className={'material-icons'} style={{marginTop: 12.5, marginLeft: -5}}>expand_more</i>
              </button>
              </div>
              :
              <div style={{marginTop: '0.5rem', marginRight: '1rem'}}>
                <Skeleton height="30px" width= "5rem"/>
              </div>
            }
            {
              showNotificationMenu && currentUser && currentUser.notifications ? 
              <NotificationMenu 
                setShowNotificationMenu={setShowNotificationMenu}
                user={currentUser.credentials}
                notifications={currentUser.notifications}
              />
              : 
              null
            }
            {
              showUserMenu && currentUser ? 
              <UserMenu 
                onLogoutClick={onLogoutClick}
                setShowUserMenu={setShowUserMenu}
                user={currentUser.credentials}
              />
              :
              null
            }
            <LeftSidebar 
              user={currentUser && currentUser.credentials}
            />
        </div>
    )
}

export default Navbar;