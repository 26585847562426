import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './UpdateUserModal.scss';

// Components
import FormInput from '../FormInput/FormInput';
import Tooltip from '@material-ui/core/Tooltip';
import ModalHeader from '../ModalHeader/ModalHeader';

const CLASSNAME = 'databot';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px'
  },
  avatar: `
    &:hover {
        cursor: pointer;
    }
  `
}));


function UpdateUserModal(props) {
    const {
        user,
        handleClose,
        open
    } = props;

    const classes = useStyles();
    const [name, setFullName] = useState(null);
    const [email, setEmail] = useState(null);
    const [errors, setErrors] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [image, setImage] = useState(user.image);
    const openMenu = Boolean(anchorEl);
    const options =
    [
      {title: 'Upload photo', icon: <i className="material-icons" style={{marginRight: 5}}>publish</i>, function: (e) => handleUploadClick(e)},
      {title: 'Revert avatar', icon: <i className="material-icons" style={{marginRight: 5}}>restore</i>, function: () => setImage(null)},
    ];

    useEffect(() => {
        if (props.errors){
            setErrors(props.errors);
        }
    }, [props.errors])

    const handleSubmit = (e) => {
        e.preventDefault();
        handleClose();
    }

    const handleChange = (e) => {
        const { value, name } = e.target;

        switch(name) {
            case 'email': 
                setEmail(value);
                break;
            case 'fullName':
                setFullName(value);
                break;
            default:
                console.log('handleChange');
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleUploadChange = event => {
        const fileUploaded = event.target.files[0];
        setImage(fileUploaded);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                    <ModalHeader title={"Edit Profile"} handleClose={handleClose} icon={<i className="material-icons" style={{marginRight: 5, marginTop: 2}}>edit</i>}/>
                    <div style={{width: '100%', display:'flex', justifyContent: 'center'}}>
                        <div className={CLASSNAME + '_hover'}>
                            <Tooltip title="Update avatar" aria-label="update avatar">
                                <Avatar className={CLASSNAME + '_updateUserModal-content-avatar'} src={image ? image : null} onClick={handleClick}>
                                    {user && user.name.split(" ").length === 2 ? user.name.split(" ")[0][0].toUpperCase() + user.name.split(" ")[1][0].toUpperCase() : user.name[0]}
                                </Avatar>
                            </Tooltip>
                        </div>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={openMenu}
                            onClose={handleCloseMenu}
                            PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                marginTop: '6rem'
                            },
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem 
                                    key={option.title} 
                                    selected={option === 'Pyxis'}
                                    onClick={(e) => {
                                        handleCloseMenu();
                                        option.function(e);
                                    }}>
                                    <div style={{display: "flex"}}>
                                        {option.icon}
                                        {option.title}
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleUploadChange}
                                            style={{display: 'none'}}
                                        />
                                    </div>
                                </MenuItem>
                                ))}
                        </Menu>
                    </div>
                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                        <FormInput 
                            name="fullName" 
                            type="text" 
                            value={name} 
                            handleChange={handleChange}
                            label="Full Name"
                        />
                        <p className={CLASSNAME + '_registerPage--wrapper-error'}>{errors.name}</p>
                        <FormInput 
                            name="email" 
                            type="email" 
                            value={email} 
                            handleChange={handleChange}
                            label="Email" 
                        />
                        <p className={CLASSNAME + '_registerPage--wrapper-error'}>{errors.email}</p>
                        <div className={CLASSNAME + '_registerPage--wrapper-buttons'}>
                            <button type="submit" className={CLASSNAME + '_updateUserModal-content-button-save'}>save</button>
                            <button className={CLASSNAME + '_updateUserModal-content-button'} onClick={(e) => {setImage(null); e.preventDefault();handleClose();}}>cancel</button>
                        </div>
                    </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default UpdateUserModal;