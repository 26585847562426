import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './DeleteModal.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

// Components 
import ModalHeader from '../ModalHeader/ModalHeader';

const CLASSNAME = 'databot';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'calc(100% + 4rem)',
        marginLeft: '-2rem',
        marginTop: '-1rem',
        marginBottom: '1rem'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.25rem'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function DeleteModal(props) {
    const {
        handleClose,
        open,
        handleDelete,
        loading,
        connectionId
    } = props;

    const classes = useStyles();

    return (
        <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
            <div className={classes.paper}>
                {
                    loading ?
                    <div className={classes.root}>
                        <LinearProgress />
                    </div>
                    :
                    null
                }
                <ModalHeader title={connectionId ? "Delete connection" : "Delete post"} handleClose={handleClose} icon={<i className="material-icons" style={{marginRight: 5, marginTop: 2}}>delete</i>}/>
                <h3 className={CLASSNAME + '_datafeedCard-message'}>Are you sure you want to delete this {connectionId ? 'connection' : 'post'}?</h3>
                <button className={CLASSNAME + '_deleteModal-cancel'} onClick={handleClose}>Cancel</button>
                <button className={CLASSNAME + '_deleteModal-delete'} onClick={handleDelete}>Delete</button>
            </div>
            </Fade>
        </Modal>
        </div>
    );
}