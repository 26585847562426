import React, { useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';
import './CommonBaseTable';

// Components
import EmptyState from '../EmptyState/EmptyState';

export default function CommonBaseTable(props) {
    const {
        tableData
    } = props;

    const SortOrder = {
        ASC: 'asc',
        DESC: 'desc',
    };

    const tableColumns = tableData && tableData[0] && Object.keys(tableData[0]);
    const [sortBy, setSortBy] = useState({ key: tableColumns && tableColumns[0], order: SortOrder.DESC })

    const columns = tableColumns && tableColumns.map(column => {
        return {
            key: column,
            dataKey: column,
            width: 150,
            title: column,
            resizable: true,
            maxwidth: 300,
            sortable: true
        }
    })

    const onColumnSort = (e) => {
        setSortBy(e);
        tableData.sort(function(a, b) {
            var x = a[e.key]; var y = b[e.key];
            if (e.order === 'desc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            } else if (e.order === 'asc') {
                return ((y < x) ? -1 : ((y > x) ? 1 : 0));
            }
            return null;
        });
    }

    return (
        <AutoResizer>
            {({ width, height }) => (
                <BaseTable
                    fixed
                    width={width}
                    height={height}
                    data={tableData}
                    columns={columns}
                    emptyRenderer={<EmptyState />}
                    onColumnSort={onColumnSort}
                    sortBy={sortBy}
                />
            )}
        </AutoResizer>
    )
}