import React, {  } from 'react';
import './TablePage.scss';
// import Skeleton from '@yisheng90/react-loading';
// import { Link } from 'react-router-dom';
// import {
//     useParams
// } from "react-router-dom";

// Components
// import Typography from '@material-ui/core/Typography';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import CommonBaseTable from '../../components/CommonBaseTable/CommonBaseTable';
import CommonSearchbar from '../../components/CommonSearchbar/CommonSearchbar';
// import OptionButton from '../../components/OptionButton/OptionButton';

const CLASSNAME = 'databot';

function TablePage() {
    // const { id, database } = useParams();
    // const [searchData, setSearchData] = useState(null);

    // useEffect(() => {
    //     if ( currentMessage && currentMessage._id !== id ) {
    //         props.fetchMessage(id, database);
    //     } else if (!currentMessage) {
    //         props.fetchMessage(id, database);
    //     }
    // }, [currentMessage, database, id, props]);

    // const onSearchChange = (e) => {
    //     let data = null;

    //     if(e !== '' && currentMessage && currentMessage.tableData) {
    //         data = currentMessage.tableData.filter((row) => {
    //             let rowData = null;
    //             Object.values(row).forEach((value) => {
    //                 if(typeof value === 'string' && value.toLocaleLowerCase().includes(e.toLocaleLowerCase())) {
    //                     rowData = row;
    //                 }
    //             })
    //             return rowData
    //         })
    //     }
    //     setSearchData(data);
    // }

    return (
        <div className={CLASSNAME + '_tablePage'}>
            <div className={CLASSNAME + '_tablePage-content'} style={{width: "calc(100% - 200px)", left:"200px"}}>
                <div className={CLASSNAME + '_tablePage-content-header'}>
                <h3 className={CLASSNAME + '_tablePage-content-title'}>Data Table</h3>
                {/* {
                    currentMessage && database ? 
                        <Breadcrumbs aria-label="breadcrumb" className={CLASSNAME + '_tablePage-content-breadcrums'}>
                            <Link color="inherit" to={`/Dashboard/${database}`} onClick={() => props.setCurrentMessage(null)}>
                                {database}
                            </Link>
                            <Typography color="textPrimary">{currentMessage._id}</Typography>
                        </Breadcrumbs>
                        :
                        <Breadcrumbs aria-label="breadcrumb" className={CLASSNAME + '_tablePage-content-breadcrums'}>
                            <Skeleton width="10rem" height="1rem"/>
                            <Skeleton width="20rem" height="1rem"/>
                        </Breadcrumbs>
                } */}
                {/* {
                    currentMessage && database ? 
                    <div style={{display: "flex", padding: "0 15px", marginTop: -15, justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                            <div className={CLASSNAME + '_welcomeMessage-example'} style={{marginRight: "1rem", display: "flex"}}>
                                <p>Message: </p>
                                <p className={CLASSNAME + '_welcomeMessage-example-text'}>{currentMessage.message}</p>
                            </div>
                            <div className={CLASSNAME + '_welcomeMessage-example'} style={{marginRight: "1rem", display: "flex"}}>
                                <p>SQL: </p>
                                <p className={CLASSNAME + '_welcomeMessage-example-text'}>{currentMessage.sql}</p>
                            </div>
                        </div>
                        <OptionButton 
                            tableData={currentMessage.tableData}
                            id={currentMessage._id}
                            database={database}
                        />
                    </div>
                    :
                    <Skeleton width="40rem" height="1rem" style={{padding: "0 15px", marginTop: 0}}/>
                } */}
                </div>
                <CommonSearchbar 
                    // onSearchChange={onSearchChange}
                    placeholder='Search table...'
                />
                {/* {
                    currentMessage && database ?
                        <div className={CLASSNAME + '_tablePage-table'}>
                            <div style={{height: "55vh", width: "calc(62vw + 220px)", marginBottom: "2rem"}}>
                                <CommonBaseTable 
                                    tableData={searchData ? searchData : currentMessage && currentMessage.tableData}
                                />
                            </div>
                        </div>
                        :
                        <Skeleton height="40vh" width= "60vw" style={{padding: "0 15px", marginTop: 0}}/>
                } */}
            </div>
        </div>
    )
}

  
export default TablePage;