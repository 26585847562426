import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import './NotificationMenu.scss';

const CLASSNAME = 'databot';

export default function NotificationMenu(props) {
    const {
        user,
        setShowNotificationMenu,
        notifications
    } = props;

    dayjs.extend(relativeTime);

    console.log(notifications);

    function useOutside(ref) {
        useEffect(() => {
        
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) 
            && event.target.className !== 'databot_notification--button') {
              setShowNotificationMenu(false);
            }
          }
          
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    function Outside(props) {
        const wrapperRef = useRef(null);
        useOutside(wrapperRef);
    
        return <div ref={wrapperRef} className={CLASSNAME + '_notificationMenu'}>{props.children}</div>;
    }

    function handleClick(notification) {

    }

    return (
        <Outside>
            {
              notifications ? 
              <div>
                <h3>Notifications</h3>
                {
                  notifications.map(notification => (
                      <Link to={`/feed/${notification.postId}`} onClick={handleClick}>
                          <div style={{display: 'flex'}} className={CLASSNAME + '_notificationMenu-link'}>
                              <Avatar src={notification && notification.imageUrl ? notification.imageUrl : null} className={CLASSNAME + '_userMenu-avatar'}>{notification && notification.sender.split(" ").length === 2 ? notification.sender.split(" ")[0][0].toUpperCase() + notification.sender.split(" ")[1][0].toUpperCase() : notification.sender[0]}</Avatar>
                              <div>
                                <p className={CLASSNAME + '_notificationMenu-link-title'}><b>{notification.sender}</b> {notification.type}{notification.type === 'favourite' ? 'd' : 'ed on'} your post</p>
                                <p className={CLASSNAME + '_notificationMenu-link-time'}> {dayjs(notification.createdAt).fromNow()}</p>
                              </div>
                              {
                                notification.read ? 
                                <div className={CLASSNAME + '_notificationMenu-link-not-read'}></div>
                                :
                                null
                              }
                          </div>
                      </Link>
                  ))
                }
              </div>
              :
              <h3>`${user.username} has not notfications`</h3>
            }
        </Outside>
    );
}