import React, {Fragment, useState, useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import userService from '../../services/User';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";

const CLASSNAME = 'databot';

export default function Searchbar() {

  const [allUsers, setAllUsers] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  console.log(error, allUsers);

  useEffect(() => {
    async function getData() {
      const {error, data} = await userService.getAllUsers();
    
      if (error) {
        setError(error)
      } else {
        setAllUsers(data);
      }
    }

    if(!allUsers) {
      getData();
    }

  }, [allUsers]);

  const handleSelect = (value) => {
    history.push(`/Profile/overview/${value.username}`);
    window.location.reload();
  }

  return (
    <div style={{ width: 320 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={allUsers ? allUsers : []}
        onChange={(event, value) => handleSelect(value)}
        getOptionLabel={option => option.username}
        renderInput={(params) => (
            <div ref={params.InputProps.ref}>
                <input style={{ width: 300, padding: '0.5rem', marginTop: '0.5rem' }} type="text" {...params.inputProps} placeholder="Search Databot" />
            </div>
        )}
        renderOption={option => {
          return (
              <Fragment>
                <Avatar src={option && option.imageUrl ? option.imageUrl : null} className={CLASSNAME + '_userMenu-avatar'}>{option && option.name.split(" ").length === 2 ? option.name.split(" ")[0][0].toUpperCase() + option.name.split(" ")[1][0].toUpperCase() : option.name[0]}</Avatar>
                {option.username}
              </Fragment>
          );
      }}
      />
    </div>
  );
}
