import React, {useState, useEffect} from 'react';
import './GettingStartedPage.scss';
import userService from '../../services/User';
import { Link } from 'react-router-dom';
import Skeleton from '@yisheng90/react-loading';

const CLASSNAME = 'databot';

function GettingStartedPage() {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    console.log(user, error);
    useEffect(() => {
        async function getData() {
            const userData = await userService.getAuthenticatedUserDetails();
    
            if (userData.error) {
              setError({
                ...userData.error
              })
            } else {
              setUser(userData.data.credentials);
            }
          }
    
          if(!user) {
            getData();
          }

    }, [user]);

    return (
        <div className={CLASSNAME + '_gettingStartedPage'}>
            <div className={CLASSNAME + '_gettingStartedPage-content'} style={{width: "calc(100% - 200px)", left:"200px"}}>
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title'}>Getting Started</h3>
                {
                    user ? 
                    <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>Welcome to Databot, a natural language interface to MySQL databases.</p>
                    :
                    <Skeleton height="30px" width= "100%" style={{marginTop: '0.5rem'}}/>
                }
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>Query</h3>
                {
                    user ?
                    <div>
                        <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>To query your database with natural language head to the <Link to='/Query'>Query</Link> page, here you can select a connection and the table in that database. To get started try:</p>
                        <div className={CLASSNAME + '_gettingStartedPage-content-try'}>
                            <code className={CLASSNAME + '_gettingStartedPage-content-code'}>Get all data</code>
                        </div>
                    </div>
                    :
                    <Skeleton height="75px" width= "100%" style={{marginTop: '0.5rem'}}/>
                }
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>Datafeed</h3>
                {
                    user ? 
                    <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>The <Link to={`/Datafeed/${user && user.username}/new`}>Datafeed</Link> allows users to share data they have found.</p>
                    :
                    <Skeleton height="30px" width= "100%" style={{marginTop: '0.5rem'}}/>
                }
                <h3 className={CLASSNAME + '_gettingStartedPage-content-title-sub'}>Word Bank</h3>
                {
                    user ? 
                    <p className={CLASSNAME + '_gettingStartedPage-content-paragraph'}>The <Link to={`/WordBank`}>Word Bank</Link> is a dictionary of SQL syntax that shows which words Databot is able to comprehend.</p>
                    :
                    <Skeleton height="30px" width= "100%" style={{marginTop: '0.5rem'}}/>
                }
            </div>
            <div className={CLASSNAME + '_gettingStartedPage-nav'}>
                <h3 className={CLASSNAME + '_gettingStartedPage-nav-title'}>Contents</h3>
                <p className={CLASSNAME + '_gettingStartedPage-nav-title-sub'}>Query</p>
                <p className={CLASSNAME + '_gettingStartedPage-nav-title-sub'}>Datafeed</p>
                <p className={CLASSNAME + '_gettingStartedPage-nav-title-sub'}>Word Bank</p>
            </div>
        </div>
    );
} 
  
export default GettingStartedPage;