import React, { useState, useEffect } from 'react';
import './DatafeedPage.scss';
import userService from '../../services/User';
import feedService from '../../services/Feed';
import {
    useParams
} from "react-router-dom";

// Pages
import TrendingPage from '../../pages/TrendingPage/TrendingPage';

// Components
import DatafeedNavbar from '../../components/DatafeedNavbar/DatafeedNavbar';
import Datafeed from '../../components/Datafeed/Datafeed';

const CLASSNAME = 'databot';

function DatafeedPage() {
    const [currentUser, setCurrentUser] = useState(null);
    const { feed } = useParams();
    const [feedData, setFeedData] = useState(null);
    const [favourites, setUserFavourites] = useState(null);
    const [error, setError] = useState(null);
    console.log(error);

    useEffect(() => {
        async function getData() {
            const userData = await userService.getAuthenticatedUserDetails();
            const feedData = await feedService.getAllPosts();
            console.log(feedData);
            if (userData.error || feedData.error) {
              setError({
                ...userData.error,
                ...feedData.error
              })
            } else {
              setCurrentUser(userData.data);
              setUserFavourites(userData.data.favourties);
              setFeedData(feedData.data);

            }
          }
    
          if(!currentUser) {
            getData();
          }

    }, [currentUser, feed]);

    const showSection = (feed) => {
        if(feed === 'trending') {
            return (
                <TrendingPage />
            );
        } else if (feed === 'hot') {
            return (
                <Datafeed 
                    user={currentUser && currentUser.credentials}
                    userFavourites={currentUser && currentUser.favourties}
                    setUserFavourites={setUserFavourites}
                    feedData={feedData}
                />
            );
        } else if (feed === 'new') {
            return (
                <Datafeed 
                    user={currentUser && currentUser.credentials}
                    userFavourites={favourites}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    setFeedData={setFeedData}
                    feedData={feedData}
                    setUserFavourites={setUserFavourites}
                />
            )
        }
    }

    return (
        <div className={CLASSNAME + '_datafeedPage'}>
            <div className={CLASSNAME + '_datafeedPage-content'} style={{width: "calc(100% - 200px)", left:"200px"}}>
                <DatafeedNavbar 
                    user={currentUser && currentUser.credentials}
                    feed={feed}
                />
                {
                    showSection(feed)
                }
            </div>
        </div>
    );
}
  
export default DatafeedPage;